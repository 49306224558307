/* TODO MOVER TUDO DAQUI PARA BAIXO PARA ARQUIVOS DIFERENTES */
 
@import url("./assets/style/black-rainboow.animation.css");
@import url("./assets/style/typograph.css");
@import url("./assets/style/heartsanimation.css");


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont,"Roboto", 'Fira Sans', 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

html {
	font-size: 18px;

	scroll-behavior: smooth;

	.swiper-cube {
		overflow: hidden;
	}

}

