.black-rainboow-animation {
	position: relative;
	margin: 3px;
	background: linear-gradient(0deg, oklch(var(--nc)), oklch(var(--p)));
}

.black-rainboow-animation:before, .black-rainboow-animation:after {
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
	background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
		#0000ff, #00ff00,#ffff00, #ff0000);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	z-index: -1;
	animation: steam 20s linear infinite;
}

@keyframes steam {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.black-rainboow-animation:after {
	filter: blur(50px);
}


.animated-opacity {
	animation: filterAnimation 6s ease-in-out infinite alternate;
}

.rainbow-road-notice {
	--angle: 0deg;
	width: 92vmin;
	height: 92vmin;
	border: 1vmin solid;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	animation: 6s rotate linear infinite;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	box-shadow: 0 0 18px white;
	transition: box-shadow 0.3s ease-in-out;


}

@keyframes rotate {
	to {
		--angle: 360deg;
	}
}

@property --angle {
	syntax: '<angle>';
	initial-value: 0deg;
	inherits: false;
}

@keyframes filterAnimation {
	0% {
		opacity: 0.7;
	}

	/* 50% {
        opacity: 0.6;
      } */
	100% {
		opacity: 0.4;
	}

}
